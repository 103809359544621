// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Mindsaurus',
		link: 'https://mindsaurus.com',
		category: 'Laravel',
		img: require('@/assets/images/mindsaurus.jpg'),
	},
	{
		id: 2,
		title: 'Saranghero',
		link: 'https://saranghero.com',
		category: 'Laravel',
		img: require('@/assets/images/saranghero.jpg'),
	},
	{
		id: 3,
		title: 'Mindhippo',
		link: 'https://mindhippo.com',
		category: 'Laravel',
		img: require('@/assets/images/mindhippo.jpg'),
	},
	{
		id: 4,
		title: 'Type Saga',
		link: 'https://typesaga.com',
		category: 'Laravel',
		img: require('@/assets/images/typesaga.jpg'),
	},
	{
		id: 5,
		title: 'Cadacoco',
		link: 'https://cadacoco.com',
		category: 'Tailwind & VueJS',
		img: require('@/assets/images/cadacoco.jpg'),
	},
	{
		id: 6,
		title: 'CloudWay',
		link: 'https://cloudway.com',
		category: 'WordPress CMS',
		img: require('@/assets/images/cloudway.jpg'),
	},
	{
		id: 7,
		title: 'Point Taken',
		link: 'https://pointtaken.com',
		category: 'WordPress CMS',
		img: require('@/assets/images/pointtaken.jpg'),
	},
	{
		id: 8,
		title: 'Checkin',
		link: 'https://checkin.no',
		category: 'HubSpot CMS',
		img: require('@/assets/images/checkin.jpg'),
	},
	{
		id: 9,
		title: 'Airlock',
		link: 'https://airlockdigital.com',
		category: 'HubSpot CMS',
		img: require('@/assets/images/airlockdigital.jpg'),
	},
	{
		id: 10,
		title: 'Scalabriniansisters',
		link: 'https://www.scalabrinian.org/',
		category: 'WordPress CMS',
		img: require('@/assets/images/scalabriniansisters.jpg'),
	},
	{
		id: 11,
		title: 'Stand Firm Architect',
		link: 'https://standfirmarchitect.com',
		category: 'WordPress CMS',
		img: require('@/assets/images/standfirm.jpg'),
	},
];

export default projects;
