<script>
export default {
	props: ['project'],
};
</script>

<template>
	<a
		:href="project.link" target="_blank"
		class="mb-10 shadow-lg cursor-pointer rounded-xl hover:shadow-xl sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
		aria-label="Single Project"
	>
		<div>
			<img
				:src="project.img"
				:alt="project.title"
				class="border-none rounded-t-xl"
			/>
		</div>
		<div class="px-4 py-6 text-center">
			<p
				class="mb-2 text-xl font-semibold font-general-semibold text-ternary-dark dark:text-ternary-light"
			>
				{{ project.title }}
			</p>
			<span
				class="text-lg font-general-medium text-ternary-dark dark:text-ternary-light"
				>{{ project.category }}</span
			>
		</div>
	</a>
</template>

<style lang="scss" scoped></style>
